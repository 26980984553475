import * as React from "react";
import * as ReactDOM from "react-dom";
import * as Cookies from "es-cookie";

const COOKIES_CHOICE_COOKIE = "hsk-cookies";
const EXPIRATION_DELAY_IN_DAYS = 365 * 10;

class CookiesDialog extends React.PureComponent<{}, CookiesDialogState> {
    state: CookiesDialogState = {
        cookiesChoice: null,
        isOpen: false,
    };
    render = () => {
        const { isOpen, cookiesChoice } = this.state;
        if (!isOpen) {
            return null;
        }
        return (
            <div id="cookies-modal" className="modal is-active">
                <div className="modal-background"></div>
                <div className="modal-card">
                    <div className="card has-background-white">
                        <header className="modal-card-head">
                            <p className="modal-card-title">{ __("Cookies") }</p>
                        </header>
                        <section className="modal-card-body">
                            <div className="content">
                                <p>
                                    { __("This website uses cookies for personalization, statistics and advertisement. By closing this popup, you agree to the use of these cookies.") }
                                </p>
                                <p>
                                    { __("You may opt out of personalized advertisement.") }
                                </p>
                            </div>
                            <div className="control">
                                <div>
                                    <label className="radio">
                                        <input
                                            id="personalized-ads"
                                            type="radio"
                                            checked={cookiesChoice === "PersonalizedAds"}
                                            onClick={() => this.setState({ cookiesChoice: "PersonalizedAds" })}
                                        />
                                        {  ` ${__("Personalized ads")}` }
                                    </label>
                                </div>
                                <div>
                                    <label className="radio">
                                        <input
                                            id="no-personalized-ads"
                                            type="radio"
                                            checked={cookiesChoice === "NoPersonalizedAds"}
                                            onClick={() => this.setState({ cookiesChoice: "NoPersonalizedAds" })}
                                        />
                                        { ` ${__("Opt out of personalized ads")}` }
                                    </label>
                                </div>
                            </div>
                        </section>
                        <footer className="modal-card-foot">
                            <button
                                className="button is-success"
                                disabled={cookiesChoice === null}
                                onClick={cookiesChoice !== null ? this.handleOk : undefined}
                            >
                                { __("OK") }
                            </button>
                        </footer>
                    </div>
                </div>
            </div>
        );
    };

    private handleOk = () => {
        const { cookiesChoice } = this.state;
        if (cookiesChoice === null) {
            return;
        }
        Cookies.set(COOKIES_CHOICE_COOKIE, cookiesChoice, { expires: EXPIRATION_DELAY_IN_DAYS, path: "/" });
        this.setState({
            isOpen: false,
        });
    };
}

interface CookiesDialogState {
    cookiesChoice: CookiesChoice,
    isOpen: boolean;
}

type CookiesChoice = "PersonalizedAds" | "NoPersonalizedAds" | null;

function init() {
    window.addEventListener("DOMContentLoaded", () => {
        ReactDOM.render(<CookiesDialog/>,
            document.getElementById("cookies-container"));
    });
}

init();
